import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import { PageParams } from "../../types";

import Logo from "../images/svg/aplinet_logo_text.inline.svg";
import Hero from "../components/hero/hero";

const NotFoundPage = (params: PageParams) => {
  params = {
    pageContext: {
      language: "pl",
    },
  } as PageParams;
  const language = params.pageContext.language;
  return (
    <>
      <SEO title="404: Not found"  lang={language} />
      <Hero>
        <div className="container-xs header-padding">
          <Logo
            style={{
              maxWidth: "300px",
            }}
          />
          {/* <h1 >Aplinet</h1> */}
          <div className="header-subline">
            <h2 className="subtitle">404 | Niestety nie ma takiej strony</h2>
          </div>
          <h4>Ale są inne: np: Portfolio</h4>
        </div>
      </Hero>

      <section>
        <h2>Mapa strony:</h2>
      </section>
    </>
  );
};
export default NotFoundPage;
